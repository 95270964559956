@use '../abstract/' as *;

.nav-menu {
  display: none;
}

.navbar.dark .nav-menu .nav-link > a {
  @include colors($bg-color: transparent, $color: var(--colors-neutral-900));
}

@include mediaQuery(lg) {
  .navbar {
    @include flex($flow: row, $align-items: center, $justify-content: flex-end);
    gap: 1rem;

    .nav-menu {
      @include flex(
        $flow: row,
        $align-items: center,
        $justify-content: flex-end
      );
      gap: 1rem;
      list-style: none;
      padding: 0;

      .nav-link > a {
        text-decoration: none;
        color: var(--colors-neutral-100);
      }
    }
  }
}
