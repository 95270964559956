@use '../functions';

@mixin mediaQuery($breakpoint) {
    @if $breakpoint == md {
        @media (min-width: #{functions.breakPoints(md)}) {
            @content;
        }
    } @else if $breakpoint == lg {
        @media (min-width: #{functions.breakPoints(lg)}) {
            @content;
        }
    } @else if $breakpoint == xl {
        @media (min-width: #{functions.breakPoints(xl)}) {
            @content;
        }
    }
}
