@use '../abstract/' as *;

.gallery-grid {
  display: grid;
  gap: 1rem;
  padding: 1rem;

  .grid--item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    padding-bottom: 2rem;
  }
}

@include mediaQuery(md) {
  .gallery-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    padding: 2rem;
  }
}

@include mediaQuery(lg) {
  .gallery-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
