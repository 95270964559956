@use '../abstract/' as *;

.btn {
  padding: 0.75rem;
  border: none;
  border-radius: 0.25rem;
  font-size: 0.9rem;
  cursor: pointer;
  @include fontFamily();
  @include fontWeight($fontWeight: var(--font-weight-700));
}

.arrow-btn {
  @include margin(0.75rem, 0, 1.5rem);
  @include padding(1rem);
  @include fontFamily();
  box-shadow: 0 1rem 3.5rem 0.1rem rgba(0, 0, 0, 0.329);
  width: min(15rem, 80%);
  font-size: 0.8rem;
  text-align: left;
  border: none;
  @include fontWeight($fontWeight: var(--font-weight-700));
}

.arrow-primary {
  @include bgImage(
    $src: $arrow-btn-dark,
    $bg-size: 1.5rem,
    $bg-position: 95%,
    $bg-repeat: no-repeat
  );

  &:hover {
    @include bgImage(
      $src: $arrow-btn-light,
      $bg-size: 1.5rem,
      $bg-position: 95%,
      $bg-repeat: no-repeat
    );
  }
}

.arrow-secondary {
  @include bgImage(
    $src: $arrow-btn-light,
    $bg-size: 1.5rem,
    $bg-position: 95%,
    $bg-repeat: no-repeat
  );

  &:hover {
    @include bgImage(
      $src: $arrow-btn-dark,
      $bg-size: 1.5rem,
      $bg-position: 95%,
      $bg-repeat: no-repeat
    );
  }
}

.btn-primary {
  @include colors(
    $bg-color: var(--colors-primary-600),
    $color: var(--colors-neutral-900)
  );

  &:hover {
    @include colors(
      $bg-color: var(--colors-neutral-900),
      $color: var(--colors-neutral-100)
    );
  }
}

.btn-secondary {
  @include colors(
    $bg-color: var(--colors-neutral-900),
    $color: var(--colors-neutral-100)
  );

  &:hover {
    @include colors(
      $bg-color: var(--colors-primary-600),
      $color: var(--colors-neutral-900)
    );
  }
}

.btn-wrapper {
  @include flex(row, space-evenly, flex-end);
  gap: 0.5rem;
  min-width: 15rem;
  margin-left: 0.75rem;

  > * {
    width: 100%;
  }
}
