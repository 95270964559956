.text-center {
  text-align: center;
}

.hyperlink {
  text-decoration: underline;
  font-weight: bold;
}

.overflowHidden {
  overflow: hidden;
}