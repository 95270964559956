@use '../abstract/' as *;

.modal-wrapper {
  position: fixed;
  min-height: 100vh;
  min-width: 100%;
  z-index: 2;
  display: none;
  justify-content: center;
  align-items: center;
  background: rgba(black, 0.7);

  .modal {
    width: min(90%, 45em);
    background-color: white;
    border-radius: 0.25rem;

    .modal-container {
      max-height: calc(90vh - 200px);
      overflow-y: auto;
      padding: 1rem;

      h2 {
        color: var(--colors-primary-600);
      }
    }

    .modal-container::-webkit-scrollbar {
      display: none;
    }

    .modal-footer {
      @include flex(
        $flow: row,
        $justify-content: flex-end,
        $align-items: flex-end
      );
      padding: 2rem;
      box-shadow: 0px -1px 20px #88888814;

      button {
        padding: 1rem 2.5rem;
      }
    }
  }
}

.open-modal {
  display: flex;
}

@include mediaQuery(md) {
  .modal-wrapper > .modal > .modal-container {
    padding: 2rem;
  }
}

@include mediaQuery(xl) {
  .modal-wrapper > .modal > .modal-container {
    padding: 2rem;
    max-height: calc(80vh - 200px);
  }
}
