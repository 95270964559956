@use '../abstract/' as *;

@include keyFrames($animation-name: fade) {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

.slider {
  position: relative;
  height: 100vh;
  width: 100%;
}

.fade {
  @include animation($a-name: fade, $a-duration: 1.5s);
}

.burners img {
  object-position: center !important;
}

.slideshow-container {
  position: relative;
  height: 100%;
  width: 100%;

  .slide {
    height: 100%;
    width: 100%;

    .slide-image,
    .slide-info {
      min-height: 50%;
      height: 50%;
    }

    .slide-info {
      display: grid;
      place-items: flex-start;

      h2 {
        margin-top: 1rem;
      }

      .product-description {
        list-style: none;
        padding-left: 0;
        font-size: min(3.5vw, 1rem);

        li {
          padding-bottom: 0.5rem;
        }
      }
    }

    .slide-image {
      order: 2;
    }

    .slide-image img {
      object-fit: cover;
      object-position: top;
      width: 100%;
      height: 100%;
    }
  }
}

.slider-btn {
  position: absolute;
  inset: 0;
  margin: auto;
  padding: 1rem;

  @include flex(
    $flow: row,
    $justify-content: space-between,
    $align-items: center
  );
  @include colors(
    $color: var(--colors-primary-800),
    $bg-color: var(--colors-neutral-100)
  );
  @include size(1rem, 3rem, 1rem, 3rem);
  @include borderRadius(2rem);
}

.dots-container {
  position: absolute;
  bottom: 2rem;
  left: 0;
  right: 0;

  @include flex($flow: row, $justify-content: center, $align-items: center);
  gap: 1rem;
  margin: 0 auto;

  .dot {
    cursor: pointer;
    margin: 0 2px;
    padding: 0.325rem;
    background-color: var(--colors-neutral-700);
    border-radius: 50%;
  }

  .active {
    background-color: var(--colors-primary-600);
    padding: 0.4rem;
  }
}

@include mediaQuery(md) {
  .slider {
    position: relative;
    min-height: 40vh;
    height: 40vh;
  }

  .slide-image,
  .slide-info {
    min-height: 100% !important;
    height: 100% !important;
  }

  .slide-info {
    h2 {
      margin-top: 2rem !important;
    }
  }

  .slider-btn {
    bottom: 2rem;
    top: auto;
  }

  .dots-container {
    bottom: 2rem;
  }

  .dotsLeft {
    left: 22vw;
    justify-content: flex-start;
  }

  .dotsRight {
    right: 22vw;
    justify-content: flex-end;
  }

  .burners {
    order: 2;
  }
}

@include mediaQuery(lg) {
  .slider {
    min-height: 60vh;
    height: 60vh;

    .slide-info {
      .container h2 {
        margin-top: 4rem !important;
      }
    }

    .product-description li {
      padding-bottom: 1rem !important;
    }
  }
}
