@mixin size($height, $width, $min-height, $min-width) {
  min-height: $min-height;
  min-width: $min-width;
  height: $height;
  width: $width;
}

@mixin border($b-width, $b-style, $color) {
  border: $b-width $b-style $color;
}

@mixin borderRadius(
  $t-left: 10px,
  $t-right: null,
  $b-right: null,
  $b-left: null
) {
  border-radius: $t-left $t-right $b-right $b-left;
}

// Animations
// Keyframes
@mixin keyFrames($animation-name) {
  @keyframes #{$animation-name} {
    @content;
  }
}

// Transition
@mixin transition($transition, $transform) {
  transition: $transition;
  transform: $transform;
}

@mixin animation($a-name, $a-duration) {
  animation-name: $a-name;
  animation-duration: $a-duration;
}
