@use '../abstract/' as *;

.contact-form {
  display: flex;
  flex-flow: column;
  margin-inline: auto;

  button {
    width: min(100%, 10em);
  }
}

.serverMessage {
  display: none;
  padding: 0.75rem;
  margin-bottom: 2rem;
  border-radius: 0.2rem;
}

.serverErr {
  display: block;
  border: 0.2rem solid var(--colors-feedback-err-900);
  background: var(--colors-feedback-err-100);
  color: var(--colors-feedback-err-900);
}

.serverResult {
  display: block;
  border: 0.2rem solid var(--colors-feedback-success-900);
  background: var(--colors-feedback-success-100);
  color: var(--colors-feedback-success-900);
}

.form-control {
  display: flex;
  flex-flow: column;
  gap: 1rem;
  margin-bottom: 1rem;
}

label {
  font-size: 0.75rem;
}

input,
textarea {
  width: -webkit-fill-available;
  outline: none;
  border: none;
  padding: 1rem;
  @include fontFamily();
  @include colors(
    $bg-color: var(--colors-neutral-600),
    $color: var(--colors-neutral-100)
  );
  resize: none;
}
