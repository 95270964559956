@use '../abstract/' as *;

.about {
  .about-title,
  .about-slogan {
    @include fontWeight(700);
  }

  .about-title {
    font-size: min(1rem, 2vh);
  }

  .about-slogan {
    font-size: clamp(1.75rem, 5vh, 4rem);
  }

  .about-info {
    padding-bottom: 5rem;
  }
}

.points {
  .points-grid {
    display: grid;

    .point {
      padding: 3rem 1rem 4rem;

      .point--desc {
        font-size: 0.9rem;
        font-weight: 300;
        line-height: 1.5rem;
      }
    }
  }

  .point-icon {
    @include size(
      $height: 3rem,
      $min-height: 2rem,
      $width: 3rem,
      $min-width: 2rem
    );
  }

  #natural-substance {
    @include bgImage(
      $src: $symbol-natural-substance,
      $bg-size: contain,
      $bg-position: center,
      $bg-repeat: no-repeat
    );
  }

  #burnefficency {
    @include bgImage(
      $src: $symbol-burn-effiency,
      $bg-size: contain,
      $bg-position: center,
      $bg-repeat: no-repeat
    );
  }

  #native {
    @include bgImage(
      $src: $symbol-native,
      $bg-size: contain,
      $bg-position: center,
      $bg-repeat: no-repeat
    );
  }

  #airquality {
    @include bgImage(
      $src: $symbol-air-quality,
      $bg-size: contain,
      $bg-position: center,
      $bg-repeat: no-repeat
    );
  }
}

@include mediaQuery(md) {
  .about {
    @include padding(5%, 1rem, 25%);
  }
  .points-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@include mediaQuery(lg) {
  .points-grid {
    grid-template-columns: repeat(4, 1fr);
  }

  .last-point {
    order: 4;
  }
}
